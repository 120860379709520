const getTomorrowDate = () => {
	const today = new Date();
	today.setDate(today.getDate() + 1);
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

const getTodayDate = () => {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

const getOneYearAgo = () => {
	const today = new Date();
	today.setDate(today.getDate() - 365);
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

export const queries = {
	videos: {
		content: 'videos',
		types: {
			0: 'video',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 20
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'category',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover',
						2: 'thumb',
						3: 'cover_thumb'
					},
				}],
			},
			clips: {},
			videos: {},
			categories: {}
		}],
	},
	video: {
		content: 'video',
		types: {
			0: 'video',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'category',
						1: 'film_collection',
						2: 'model'
					},
					status: 'published'
				}],
				include: [{
					resources: {
						filters: [{
							types: {
								0: 'video'
							},
							status: 'published'
						}],
						include: [{
							images: {
								filters: [{
									types: {
										0: 'cover',
										1: 'home_cover',
										2: 'thumb',
										3: 'cover_thumb'
									},
								}],
							},	
						}]
					},
					images: {
						filters: [{
							types: {
								0: 'cover',
								1: 'home_cover',
								2: 'thumb',
								3: 'cover_thumb'
							},
						}],
					},
				}],
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover',
						2: 'thumb',
						3: 'cover_thumb'
					},
				}],
			},
			related: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				include: [{
					images: {
						filters: [{
							types: {
								0: 'cover',
								1: 'thumb'
							}
						}],
					}
				}]
			},
			seo: {},
			images: {},
			clips: {},
			videos: {},
			categories: {},
			models: {}
		}],
	},
	populairModels: {
		content: 'populairModels',
		types: {
			0: 'model',
		},
		sort: {
			views: "desc"
		},
		filters: {
			status: "published"
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: "model_thumb",
						1: "square"
					}
				}]
			}
		}],
	},
	shortModels: {
		content: 'shortModels',
		short: true,
		types: {
			0: 'model',
		},
		sort: {
			recommended_at: "desc"
		},
		filters: {
			status: "published"
		},
		pagination: {
			page: 1,
			per_page: 500
		},
	},
	models: {
		content: 'models',
		types: {
			0: 'model',
		},
		sort: {
			published_at: "desc"
		},
		filters: {
			status: "published"
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: "model_thumb",
						1: "square"
					}
				}]
			}
		}],
	},
	model: {
		content: 'model',
		types: {
			0: 'model',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			resources: {
				filters: {
					status: 'published'
				},
				pagination: {
					page: 1,
					per_page: 12
				},
				include: [{
					images: {
						filters: [{
							types: {
								0: 'thumb'
							}
						}]
					},
					seo: {},
					videos: {},
					clips: {},
					extra: {}
				}]
			},
			seo: {},
			images: {
				filters: [{
					types: {
						0: "model_thumb",
						1: "square"
					}
				}],
			},
			clips: {},
			videos: {},
			extra: {}
		}],
	},
	videoOfTheWeek: {
		content: 'videoOfTheWeek',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['video_of_the_week']
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			categories: {},
			clips: {},
			videos: {}
		}],
	},
	highlightVideos: {
		content: 'highlightVideos',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['highlight_videos']
		},
		pagination: {
			page: 1,
			per_page: 3
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			categories: {},
			clips: {},
			videos: {}
		}],
	},
	videoForYou: {
		content: 'videoForYou',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['home_promo']
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'category',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover_thumb',
						1: 'home_cover',
						2: 'thumb'
					},
				}],
			},
			videos: {},
			categories: {},
			clips: {}
		}],
	},
	seksfilmsPromo: {
		content: 'seksfilmsPromo',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['seksfilms_promo']
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'category',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'home_cover',
						2: 'cover'
					},
				}],
			},
			videos: {},
			categories: {},
			clips: {}
		}],
	},
	recommendedVideos: {
		content: 'recommendedVideos',
		types: {
			0: 'video',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published',
			active: 1
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	newestRecurringVideos: {
		content: 'newestRecurringVideos',
		types: {
			0: 'video',
		},
		sort: {
			recurring_from: 'desc'
		},
		filters: {
			status: 'published',
			recurring_from: { 0: 'before,' + getTodayDate() }
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	freeVideos: {
		content: 'freeVideos',
		types: {
			0: 'video',
		},
		sort: {
			random: 'desc'
		},
		filters: {
			status: 'published',
			products: {
				0: 'free'	
			}
		},
		pagination: {
			page: 1,
			per_page: 20
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	randomVideos: {
		content: 'randomVideos',
		types: {
			0: 'video',
		},
		sort: {
			random: 'desc'
		},
		filters: {
			status: 'published',
			recurring_from: { 0: 'before,' + getOneYearAgo() }, 
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	votwFree: {
		content: 'votwFree',
		types: {
			0: 'video',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published',
			products: {
				0: 'free'	
			}
		},
		pagination: {
			page: 1,
			per_page: 3
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	mostRecent: {
		content: 'mostRecent',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			active: 1
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	planned: {
		content: 'planned',
		types: {
			0: 'video',
		},
		sort: {
			recurring_from: 'asc',
		},
		filters: {
			status: 'published',
			recurring_from: { 0: 'after,' + getTomorrowDate() }
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile',
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	tempFree: {
		content: 'tempFree',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['temporary_free']
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'category',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover',
						2: 'cover_thumb'
					},
				}],
			},
			videos: {},
			categories: {},
			labels: {}
		}],
	},
	topVideos: {
		content: 'topVideos',
		types: {
			0: 'video',
		},
		sort: {
			most_watched: 'week'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'thumb_mobile'
					},
				}],
			},
			videos: {},
			clips: {}
		}],
	},
	shows: {
		content: 'shows',
		types: {
			0: 'show',
		},
		sort: {
			published_at: "desc"
		},
		filters: {
			status: "published"
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: "episode"
					},
					status: "published"
				}],
				include: [{
					images: {},
					seo: {},
					clips: {},
					videos: {},
					extra: {}
				}]
			},
			images: {
				filters: [{
					types: {
						0: "thumb"
					}
				}]
			},
			clips: {}
		}],
	},
	show: {
		content: 'show',
		types: {
			0: 'show',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				pagination: {
					page: 1,
					per_page: 12
				},
				include: [{
					images: {
						filters: [{
							types: {
								0: 'thumb'
							},
						}],
					},
					clips: {},
				}]
			},
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
			categories: {},
			videos: {}
		}],
	},
	episode: {
		content: 'episode',
		types: {
			0: 'episode',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'show',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
				include: [{
					resources: {
						filters: [{
							types: {
								0: 'episode',
							},
							status: 'published'
						}],
						include: [{
							videos: {},
							extra: {}
						}]
					},
				}]
			},
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'album'
					},
				}],
			},
			categories: {},
			videos: {},
			extra: {}
		}],
	},
	category: {
		content: 'category',
		types: {
			0: 'category',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				pagination: {
					page: 1,
					per_page: 12
				},
				include: [{
					images: {
						filters: [{
							types: {
								0: 'thumb'
							},
						}],
					},
					clips: {},
				}]
			},
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
			categories: {}
		}],
	},
	allCategories: {
		content: 'allCategories',
		types: {
			0: 'category',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 50
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
		}]
	},
	categories: {
		content: 'categories',
		types: {
			0: 'category',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
		}]
	},
	populairCategories: {
		content: 'populairCategories',
		types: {
			0: 'category',
		},
		sort: {
			views: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
		}]
	},
	randomCategories: {
		content: 'randomCategories',
		types: {
			0: 'category',
		},
		sort: {
			random: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: "video"
					},
					status: "published"
				}],
			},
		}]
	},
	tag: {
		content: 'tag',
		types: {
			0: 'category',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
		}],
	},
	collections: {
		content: 'film_collections',
		types: {
			0: 'film_collection',
		},
		sort: {
			random: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 3
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
			},
			images: {},
			categories: {}
		}],
	},
	search : {
		content: 'search',
		types: {
			0: 'video',
          // 1: 'model',
          // 2: 'category'
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published',
		},
		search: {
			all: ''
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			seo: {},
			images: {
				filters: [{
					types: {
						0: 'thumb',
						2: 'square',
						3: 'cover',
						4: 'cover_thumb'
					}
				}],
			},
			clips: {},
			extra: {}
		}]
	},
	providers : {
		content: 'providers',
		types: {
			0: 'provider'
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published',
		},
		search: '',
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			seo: {},
			images: {
				filters: [{
					types: {
						0: 'thumb',
					}
				}],
			},
			clips: {},
			extra: {}
		}]
	},
	preroll: {
		content: 'preroll',
		types: {
			0: 'preroll',
		},
		sort: {
			random: 'desc',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			videos: {}
		}]
	},
	faqs: {
		content: 'faqs',
		types: {
			0: 'faq',
		},
		sort: {
			recommended_at: 'desc',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 20
		}
	},
	sexlinks: {
		content: 'sexlinks',
		types: {
			0: 'friend',
		},
		sort: {
			recommended_at: 'desc',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 20
		}
	},
	news: {
		content: 'news',
		types: {
			0: 'news',
		},
		sort: {
			recommended_at: 'desc',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 20
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
					}
				}],
			},
			videos: {},
			extra: {}
		}],
	},
	newsitem: {
		content: 'newsitem',
		types: {
			0: 'news',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
			videos: {},
			related: {},
			extra: {}
		}],
	},
	privatevideos: {
		content: 'privatevideos',
		types: {
			0: 'private',
		},
		sort: {
			random: 'desc',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
					}
				}],
			},
			videos: {},
			extra: {}
		}]
	},
	privatevideo: {
		content: 'privatevideo',
		types: {
			0: 'private',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
			videos: {},
			related: {},
			extra: {}
		}],
	},
	spotlight: {
		content: 'spotlight',
		types: {
			0: 'spotlight',
		},
		sort: {
			published_at: 'desc',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'thumb',
					}
				}],
			},
			extra: {}
		}]
	},
	popunder: {
		content: 'popunder',
		types: {
			0: 'popunder',
		},
		sort: {
			published_at: 'desc',
		},
		filters: {
			status: 'published',
			active: 1
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			images: {
				filters: [{
					types: {
						0: 'popunder',
					}
				}],
			},
			extra: {}
		}]
	},
};