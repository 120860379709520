  /*
  ** JS functions to load streams
  */
import Vue from 'vue'
import { mapState } from 'vuex'
import userChecks from '~/mixins/userChecks';
Vue.mixin({
  mixins: [userChecks],
  computed: {
    ...mapState({
      video: ({ videoStore: {video} }) => video,
      stream: ({ mxmStore: {stream} }) => stream
    }),
  },
  data() { 
    return {
      kind: false,
    }
  },
  methods: {
    loadTrailer(videoId) {
      this.$store.commit('mxmStore/clearStream');
      this.kind = false;
      var dataObj = [];
      dataObj.video_id = videoId;
      dataObj.resource_id = this.video.id;
      dataObj.frontend_id = 1;
      dataObj.type = 'trailer';
      dataObj.location = 'sexfilm';
      this.$store.dispatch('mxmStore/getFreeStream', dataObj);
      this.trackButtonClick('playButton', 'Play trailer', this.video.slug);
    },
    loadFreeFilm(videoId) {
      this.$store.commit('mxmStore/clearStream');
      this.kind = false;
      if (this.stream && this.stream.id && this.stream.type == 'free_film') {
        return this.$store.commit('mxmStore/setPlayerStatus', 'playing')
      }
      if (this.stream && this.stream.id && this.stream.type == 'trailer') {
        this.trailer = false;
      }
      if (this.video.has_preroll && this.$auth.loggedIn && !hasRecurringSubscription() || this.video.has_preroll && !this.$auth.loggedIn) {
        var count = this.video.preroll.length;
        var random = Math.floor(Math.random() * count);
        var dataObj = [];
        dataObj.video_id = this.video.preroll[random].videos.preroll[0].id;
        dataObj.resource_id = this.video.id;
        dataObj.frontend_id = 1;
        dataObj.type = 'preroll';
        dataObj.location = 'sexfilm';
        if (dataObj.href = this.video.preroll[random].fields.href) {
          dataObj.href = this.video.preroll[random].fields.href;
          dataObj.target = this.video.preroll[random].fields.target;
        }
        this.trackButtonClick('playButton', 'Play preroll', this.video.slug);
        this.$store.dispatch('mxmStore/getFreeStream', dataObj);
      } else {
        var dataObj = [];
        dataObj.video_id = videoId;
        dataObj.resource_id = this.video.id;
        dataObj.frontend_id = 1;
        dataObj.type = 'free_film';
        dataObj.location = 'sexfilm';
        this.$store.dispatch('mxmStore/getFreeStream', dataObj);
        this.trackButtonClick('playButton', 'Play free sexfilm', this.video.slug);
      }
    },
    loadPaidFilm(videoId) {
      this.$store.commit('mxmStore/clearStream');
      this.kind = false;
      if (this.stream && this.stream.id && this.stream.type == 'film') {
        return this.$store.commit('mxmStore/setPlayerStatus', 'playing')
      }
      if (this.video.has_preroll && this.$auth.loggedIn && this.$auth.user.recurring.length === 0 && this.video.access && this.video.access[0].sub_detail_id === 40 && !this.preroll_shown ) {
        var count = this.video.preroll.length;
        var random = Math.floor(Math.random() * count);
        var dataObj = [];
        dataObj.video_id = this.video.preroll[random].videos.preroll[0].id;
        dataObj.resource_id = this.video.id;
        dataObj.frontend_id = 1;
        dataObj.type = 'preroll';
        dataObj.location = 'sexfilm';
        if (dataObj.href = this.video.preroll[random].fields.href) {
          dataObj.href = this.video.preroll[random].fields.href;
          dataObj.target = this.video.preroll[random].fields.target;
        }
        this.preroll_shown = true;
        return this.$store.dispatch('mxmStore/getFreeStream', dataObj)
      }
      var dataObj = [];
      dataObj.video_id = videoId;
      dataObj.resource_id = this.video.id;
      dataObj.frontend_id = 1;
      dataObj.type = 'film';
      dataObj.location = 'sexfilm';

      if (this.$store.state.auth.loggedIn) {
        dataObj.user_id = this.$store.state.auth.user.id;
      }

      var phone_stream_id = this.getCookie('phone_stream_id');
      if (phone_stream_id) {
        dataObj.phone_stream_id = phone_stream_id;
        dataObj.user_id = 1;
        dataObj.subscription_id = 3;
      } else {
        dataObj.subscription_id = this.video.access[0].subscription_id;
      }

      var credit_user_subscription_id = this.getCookie('credit_user_subscription_id');
      if (credit_user_subscription_id && this.video.access[0].subscription_id === 4) {
        dataObj.credit_user_subscription_id = credit_user_subscription_id;
      }
      if (this.video.access[0].subscription_id === 4 && !credit_user_subscription_id) {
        return this.getAccessCredits();
      }
      this.$store.dispatch('mxmStore/getPaidStream', dataObj);
      this.trackButtonClick('playButton', 'Play paid sexfilm', this.video.slug);
    },
    doKillStream(type) {
      if (type == 'phone') {
        var alertObj = {};
        alertObj.type = 'warning';
        alertObj.message = 'De verbinding is verbroken. Verder kijken? Bel dan opnieuw.';
        this.$store.commit('alertStore/setAlert', alertObj, { root: true });    
        document.cookie = "phone_stream_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        this.$store.commit('videoStore/setPpmAccess', false)
        this.$store.commit('mxmStore/clearStream')
        // var self = this;
        // setTimeout(function() {
        //   self.$router.go();
        // }, 3000);
      }
      if (type == 'credits') {
        var alertObj = {};
        alertObj.type = 'warning';
        alertObj.message = 'Je hebt geen credits meer. Verder kijken? Koop dan meer credits.';
        this.$store.commit('alertStore/setAlert', alertObj, { root: true });    
        document.cookie = "credit_user_subscription_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        var self = this;
        setTimeout(function() {
          self.$router.go();
        }, 3000);
      }
    },
    getAccessCredits() {
      let query = {
        subscription: 4,
        frontend_id: this.$config.frontendId,
        user_id: this.$auth.user.id,
        resource_id: this.video.id
      };
      this.$store.dispatch('subscriptionStore/subscribeCredits', query)
    },
    setStatus(status){
      this.$store.commit('mxmStore/setPlayerStatus', status)
    },
  }
})