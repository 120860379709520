import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';
import { format, addDays, getUnixTime } from 'date-fns'

export const strict = false;

export const state = () => ({
  queries: {
    epg: {
      filters: {
        playout: 'hard',
        start: '',
      },
      sort: {
        start: 'asc'
      },
      pagination: {
        page: 1,
        per_page: 20
      },
      include: [{
        resource: {
          filters: [{
            types: {
              0: 'video',
            },
            status: 'published'
          }],
          images: {
            filters: [{
              types: {
                0: 'thumb'
              },
            }],
          },
        },
      }],
    },
    epgNow: {
      filters: {
        playout: 'hard',
        start: '',
      },
      sort: {
        start: 'asc'
      },
      pagination: {
        page: 1,
        per_page: 2
      },
      include: [{
        resource: {
          filters: [{
            types: {
              0: 'video',
            },
            status: 'published'
          }],
          images: {
            filters: [{
              types: {
                0: 'thumb'
              },
            }],
          },
        },
      }],
    },
  },
  loading: false,
  epg: [],
  epgNow: [],
})

export const actions = {
  async getEpg({commit}, query) {
    const date = new Date();
    let updateQuery = JSON.parse(JSON.stringify(query));
    updateQuery.filters.start = format(date, 'yyyy-MM-dd HH-mm');

    commit('setLoading', true); 
    await this.$axios.get(process.env.API_URL + process.env.API_PREFIX + '/epg?query=' + objectToJsonUrl(updateQuery))
    .then(response => { 
      commit('setEpg', { response: response.data, query }); 
    }).catch((error) => {
      console.log(error);
      commit('setLoading', 'error'); 
    }); 
  },
}

export const getters = {
  epg(state) {
    return state.epg;
  },
  epgNow(state) {
    return state.epgNow;
  }
}

export const mutations = {
  setEpg(state, data) {
    state.loading = 'done';
  //  var dataObj = [];
  //  dataObj.included = data.included;
  //  dataObj.data = data.data;
    var epg = data.response;

    //  moment.locale('nl'); // locale options was not used
    let currentTime = getUnixTime(new Date());
    let endtime = getUnixTime(new Date(epg.data[0].end_datetime));
    let startime = getUnixTime(new Date(epg.data[0].start_datetime));

    let getTotalPerc = endtime - startime;
    let progress = endtime - currentTime;

    let percentageLeft = progress / getTotalPerc * 100;
    let percentageDone = 100 - percentageLeft;
    let totalProgress = Math.round(percentageDone);

    epg.data.forEach((item, i) => {
      item.progress = totalProgress;

      item.start_datetime = format(new Date(item.start_datetime), "HH:mm");
      item.end_datetime = format(new Date(item.end_datetime), "HH:mm");

      if (item.resource) {
        if (item.resource && item.resource.images && item.resource.images.data.thumb) {
          item.resource.images.data.thumb.forEach(function(element) {
            if (element.type == 'thumb') {
              item.thumb = element.path;
            }
          });
        }
        item.tagline = false;
        item.slug = item.resource.slug;
        item.type = item.resource.type;
      } else {
        var random = Math.floor(Math.random() * 6);
        item.tagline = 'Exclusief voor TV';
        item.slug = false;
        item.type = 'video';
        // item.thumb = this.$config.errorepg + random + '.jpg';
      }
    });
    if (data.response && data.response.data && data.response.data.length > 2) {
      state.epg = epg;
    } else {
      state.epgNow = epg;
    }
  },
  setLoading(state, data) {
    return state.loading = data;
  },
}


const jsonToUrlValue = (value) => {
  if (typeof value === 'number' || typeof value === 'boolean' || value === null) {
    return String(value);
  } else if (typeof value === 'string') {
    // Quote the string if necessary
    if (['true', 'false', 'null'].includes(value) || !isNaN(Number(value))) {
      return `'${value}'`;
    }
    // Special handling for date strings to prevent replacing spaces with +
    // Check if the string matches the date format you're using (e.g., YYYY-MM-DD HH-MM)
    if (/^\d{4}-\d{2}-\d{2} \d{2}-\d{2}$/.test(value)) {
      // For date strings, we replace spaces with a placeholder that encodeURIComponent will not touch
      return value.replace(/ /g, 'SPACE');
    }
    // For other strings, replace spaces with +
    return value.replace(/ /g, '+');
  } else if (Array.isArray(value)) {
    return `(${value.map(jsonToUrlValue).join(',')})`;
  } else if (typeof value === 'object') {
    const entries = Object.entries(value).map(([key, val]) => `${key}:${jsonToUrlValue(val)}`);
    return `(${entries.join(',')})`;
  }
  // Fallback for undefined or functions
  return '';
};

const objectToJsonUrl = (obj) => {
  // First, convert the object to a string with our custom formatting
  let urlString = jsonToUrlValue(obj);
  // Now, encodeURIComponent the entire string to make it URL-safe, except for our date space placeholder
  urlString = encodeURIComponent(urlString);
  // Replace the placeholder 'SPACE' back to the correct URL-encoded form for a space, which is '%20'
  urlString = urlString.replace(/SPACE/g, '%20');
  return urlString;
};
