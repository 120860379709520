    import { queries } from '~/helpers/defaultQueries';
    export const state = () => ({
      queries: queries,
      loading: {
        videos: false,
        video: false,
        videoOfTheWeek: false,
        recommendedVideos: false,
        highlightVideos: false,
        videoForYou: false,
        randomVideos: false,
        freeVideos: false,
        newestRecurringVideos: false,
        topVideos: false,
        votwFree: false,
        seksfilmsPromo: false,
        mostRecent: false,
        planned: false,
        tempFree: false,
        populairModels: false,
        shortModels: false,
        models: false,
        model: false,
        shows: false,
        show: false,
        episode: false,
        populairCategories: false,
        randomCategories: false,
        allCategories: false,
        categories: false,
        category: false,
        tags: false,
        randomTags: false,
        tag: false,
        search: false,
        spotlight: false,
        privateVideos: false,
        providers: false,
        preroll: false,
        popunder: false,
        includedResources: false,
        privatevideos: false,
        privatevideo: false,
        faqs: false,
        sexlinks: false,
        news: false,
        newsitem: false,
      },
      videos: {},
      video: {},
      videoSingle: {},
      populairCategories: {},
      randomCategories: {},
      allCategories: {},
      newestRecurringVideos: {},
      freeVideos: {},
      categories: {},
      category: {},
      tags: {},
      randomTags: {},
      tag: {},
      populairModels: {},
      shortModels: {},
      models: {},
      model: {},
      videoOfTheWeek: {},
      highlightVideos: {},
      votwFree: {},
      randomVideos: {},
      mostRecent: {},
      planned: {},
      tempFree: {},
      topVideos: {},
      videoForYou: {},
      seksfilmsPromo: {},
      shows: {},
      show: {},
      episode: {},
      film_collections: {},
      recommendedVideos: {},
      search: {},
      providers: {},
      preroll: {},
      privatevideos: {},
      privatevideo: {},
      spotlight: {},
      popunder: {},
      faqs: {},
      sexlinks: {},
      news: {},
      newsitem: {},
      access: {
        access: false,
        is_free: false,
        subscription: null
      }
    })

    export const actions = {
      async getResources({commit, rootState}, query) {
        if(query.pagination && query.pagination.page === 1) {
          commit('setLoading', { type: query.content, todo: 'loading' }); 
        } else {
          commit('setLoading', { type: query.content, todo: 'loadmore' }); 
        }
        var resourceRoute = 'resources'
        if (query.short) {
          resourceRoute = 'short-resources'
          delete query.short;
        }
        if (
          query.filters.resources !== undefined ||
          query.filters.language !== undefined ||
          query.filters.recurring_from !== undefined ||
          rootState.filtersStore.filter.sort && rootState.filtersStore.filter.sort.title && rootState.filtersStore.filter.sort.title !== 'Sorteren'
          ) {
          commit('filtersStore/toggleFilterState', true, { root: true });
      } else {
        commit('filtersStore/toggleFilterState', false, { root: true });
      }

      try {
        const response = await this.$axios.get(process.env.API_URL + process.env.API_PREFIX + '/' + resourceRoute + '/nl?query=' + objectToJsonUrl(query));
        commit('setResources', { response: response.data, query });
        return response.data;
      } catch (error) {
        console.log(error);
        commit('setLoading', { type: query.content, todo: 'error' }); 
        throw error; 
      };
    },
    async getIncludedResources({commit}, query) {
      if(query.include[0].resources.pagination.page && query.include[0].resources.pagination.page === 1) {
        commit('setLoading', { type: 'includedResources', todo: 'loading' }); 
      } else {
        commit('setLoading', { type: 'includedResources', todo: 'loadmore' }); 
      }
      try {
        const response = await this.$axios.get(`${process.env.API_URL}${process.env.API_PREFIX}/resource/${query.slug}/nl?query=${objectToJsonUrl(query)}`);
        commit('setIncludedResources', { response: response.data, query });
        return response.data;
      } catch (error) {
        console.log(error);
        commit('setLoading', { type: query.content, todo: 'error' }); 
        throw error; 
      };
    },
    async getResource({ commit }, query) {
      commit('setLoading', { type: query.query.content, todo: 'loading' });
      try {
        const response = await this.$axios.get(`${process.env.API_URL}${process.env.API_PREFIX}/resource/${query.slug}/nl?query=${objectToJsonUrl(query.query)}`);

        commit('setResource', { response: response.data, query: query, checkAccess: query.checkAccess });
        commit('setLoading', { type: query.query.content, todo: 'done' });
        return response.data.data;
      } catch (error) {
        console.log(error);
        commit('setLoading', { type: query.query.content, todo: 'error' }); 
        throw error; 
      }
    },
    async checkResourceAccess({ commit }, query) {
      try {
        const response = await this.$axios.post(`${process.env.API_URL}${process.env.API_PREFIX}/user-subscription/check-access/${query.id}`);
        commit('setAccess', response.data );
        commit('setLoading', { type: query.type, todo: 'done' });
      } catch (error) {
        console.log(error);
        commit('setLoading', { type: query.type, todo: 'error' });
        this.app.$sentry.captureException(error);
        throw error; 
      }
    }

  }

  export const getters = {

  }

  export const mutations = {
    setResources(state, { response, query }) { 
      if (!state[query.content]) {
        state[query.content] = {};
      }
      response.data.forEach(function(resource) {
        if(resource.resources && resource.resources.data) {
          resource.resources.data.forEach(function(linked) {
            if (!resource[linked.type]) {
              resource[linked.type] = [];
            }
            resource[linked.type].push(linked);
          })
        }
        const now = new Date();
        const recurringDate = new Date(resource.recurring_from);
        if (recurringDate > now) {
          const diffTime = Math.abs(recurringDate - now);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          resource.recurring_diff = diffDays;
          resource.in_recurring_subscription = false;
        } 
        if (recurringDate && recurringDate > now || !recurringDate) {
          resource.in_recurring_subscription = false;
        } else {
          resource.in_recurring_subscription = true;
        }
        if (resource.extra && resource.extra.data) {
          const extra_fields = resource.extra.data.reduce((obj, item) => {
            obj = { ...obj, ...item };
            return obj;
          }, {});
          resource.extra = extra_fields;
        }
      })
      if(query.pagination.page > 1) {
        state[query.content].data = [
          ...(state[query.content].data || []),
          ...response.data
        ];
        state[query.content].pagination = response.pagination;
      } else {
        state[query.content] = response;
      }
      return state.loading[query.content] = 'done';
    },
    setIncludedResources(state, { response, query }) {
      response.data.resources.data.forEach(function(resource) {
        const now = new Date();
        let recurringDate = null;

        if (resource.recurring_from !== null) {
          recurringDate = new Date(resource.recurring_from);
        }

        if (recurringDate && recurringDate > now) {
          const diffTime = Math.abs(recurringDate - now);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          resource.recurring_diff = diffDays;
          resource.in_recurring_subscription = false;
        }

        if (recurringDate && recurringDate > now || !recurringDate) {
          resource.in_recurring_subscription = false;
        } else {
          resource.in_recurring_subscription = true;
        }

        if (resource.extra && resource.extra.data) {
          const extra_fields = resource.extra.data.reduce((obj, item) => {
            obj = { ...obj, ...item };
            return obj;
          }, {});
          resource.extra = extra_fields;
        }
      })
      if(query.include[0].resources.pagination.page > 1) {
        state[query.content].resources.data = [
          ...(state[query.content].resources.data || []),
          ...response.data.resources.data
        ];
        state[query.content].resources.pagination = response.data.resources.pagination;
      } else {
        state[query.content] = response;
      }
      return state.loading.includedResources = 'done';
    },
    setResource(state, { response, query, checkAccess }) {
      if (!state[query.query.content]) {
        state[query.query.content] = {};
      }
      const now = new Date();
      let recurringDate = null;

      if (response.data.recurring_from !== null) {
        recurringDate = new Date(response.data.recurring_from);
      }

      if (recurringDate && recurringDate > now) {
        const diffTime = Math.abs(recurringDate - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        response.data.recurring_diff = diffDays;
      } 
      if (recurringDate && recurringDate > now || !recurringDate) {
        response.data.in_recurring_subscription = false;
      } else {
        response.data.in_recurring_subscription = true;
      }

      if (response && response.data && response.data.resources && response.data.resources.data && response.data.resources.data.length) {
        response.data.resources.data.forEach(function(resource) {
          if (!response.data[resource.type]) {
            response.data[resource.type] = [];
          }
          const now = new Date();
          let includedResourceRecurringDate = null;

          if (resource.recurring_from !== null) {
            includedResourceRecurringDate = new Date(resource.recurring_from);
          }

          if (includedResourceRecurringDate && includedResourceRecurringDate > now) {
            const diffTime = Math.abs(includedResourceRecurringDate - now);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            resource.recurring_diff = diffDays;
            resource.in_recurring_subscription = false;
          }
          if (includedResourceRecurringDate && includedResourceRecurringDate > now || !includedResourceRecurringDate) {
            resource.in_recurring_subscription = false;
          } else {
            resource.in_recurring_subscription = true;
          }

          if (resource.resources && resource.resources.data && resource.resources.data.length) {
            resource.resources.data.forEach(function(childResource) {
              if (!resource[childResource.type]) {
                resource[childResource.type] = [];
              }
              if(childResource.extra) {
                const extra_fields = childResource.extra.data.reduce((obj, item) => {
                  obj = { ...obj, ...item };
                  return obj;
                }, {});
                childResource.extra = extra_fields;
              }
              resource[childResource.type].push(childResource);
            })
          }
          response.data[resource.type].push(resource);
        })
      }

      if (response && response.data && response.data.extra) {
        const extraData = response.data.extra.data;

        if (Array.isArray(extraData)) {
          const extra_fields = extraData.reduce((obj, item) => {
            obj = { ...obj, ...item };
            return obj;
          }, {});
          response.data.extra = extra_fields;
        } else {
          console.warn('extra.data is not an array or is undefined');
          response.data.extra = {}; 
        }
      }


        // If API is checking access, loading state will be set by that request
      if (!checkAccess) {
        state.loading[query.query.content] = 'done';
      }
      return state[query.query.content] = response.data;
    },
    updateQuery(state, query) {
      const contentType = query.content;
      if (contentType) {
        state.queries[contentType] = query;
      }
    },
    setAccess(state, response) {
      state.access = response;
    },
    resetAccess(state, response) {
      state.access = {
        access: false,
        is_free: false,
        subscription: null
      }
    },
    setLoading(state, { type, todo }) {
      state.loading[type] = todo;
    },
  }

  const jsonToUrlValue = (value) => {
    if (typeof value === 'number' || typeof value === 'boolean' || value === null) {
      return String(value);
    } else if (typeof value === 'string') {
        // Quote the string if necessary
      if (['true', 'false', 'null'].includes(value) || !isNaN(Number(value))) {
        return `'${value}'`;
      }
      return value.replace(/ /g, '+');
    } else if (Array.isArray(value)) {
      return `(${value.map(jsonToUrlValue).join(',')})`;
    } else if (typeof value === 'object') {
      const entries = Object.entries(value).map(([key, val]) => `${key}:${jsonToUrlValue(val)}`);
      return `(${entries.join(',')})`;
    }
    // Fallback for undefined or functions
    return '';
  };

  const objectToJsonUrl = (obj) => {
    return encodeURIComponent(jsonToUrlValue(obj));
  };