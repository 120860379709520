// Use to check if a modal popup is active

export const strict = false;

export const state = () => ({
  dropdown: false,
  filterDropdown: {
    main: false,
    sub: false
  }
})

export const getters = {
  dropdown(state) {
    return state.dropdown;
  }
}

export const mutations = {
  setDropdown(state, dropdown) {
    state.dropdown = dropdown;
  },
  setFilterDropdown(state, dropdownObj) {
    state.filterDropdown[dropdownObj.type] =
    state.filterDropdown[dropdownObj.type] === dropdownObj.value
    ? false
    : dropdownObj.value
    if (dropdownObj.type === 'main') {
      state.filterDropdown.sub = false
    }
  }

}